<template>
  <div>
    <!-- <missingDocuments></missingDocuments> -->
    <horizontalMenu></horizontalMenu>
    <div class="container grey-background mt-3">
      <v-progress-circular
        v-if="messagesLoading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div v-if="!messagesLoading" class="container message-container row">
        <h1 class="semibold-font">{{ $t('messages') }}</h1>
        <span class="grey-square message-square ml-lg-4 ml-2">{{ unreadMessagesSize }} {{ $t('new_messages') }}</span>
      </div>
      <v-simple-table v-if="!messagesLoading" class="mt-6 mb-12 documents-table">
        <tbody>
          <tr @click="displayShowMessageOnModal(message.id)" :class="message.attributes.read ? 'read-message message' : 'unread-message message'" v-for="(message) in messages" :key="message.id">
            <td width="5%">
              <div v-if="!message.attributes.read" :class="message.counter % 3 === 0  ? 'yellow-color-square' : message.counter % 2 === 0  ? 'gradient-color-square' : ''" class="date-square row">
                <span v-if="!message.attributes.urgent" class="envelope-icon"></span>
                <span v-if="message.attributes.urgent" class="envelope-urgent-icon"></span>
              </div>
              <div v-if="message.attributes.read" class="date-square row">
                <span class="checkbox-icon"></span>
              </div>
            </td>
            <td class="message">{{ message.attributes['title'] }}</td>
            <td width="5%" class="text-right">
              <div class="symbol_div">{{ $date(message.attributes['created_at']).format('DD.MM.YYYY') }}</div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <showMessageModal v-if="displayShowMessageModal" @close="displayShowMessageModal = false"></showMessageModal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import showMessageModal from '@/components/showMessageModal'
// import missingDocuments from '@/components/banners/missingDocuments.vue'
import HorizontalMenu from '../components/navigation/horizontalMenu.vue'

export default {
  name: 'messages',
  data () {
    return {
      displayShowMessageModal: false
    }
  },
  components: {
    showMessageModal,
    // missingDocuments,
    HorizontalMenu
  },
  computed: {
    ...mapGetters(['messages']),
    ...mapGetters(['unreadMessagesSize']),
    ...mapGetters(['messagesLoading'])
  },
  created () {
    this.$store.dispatch('getMessages')
  },
  methods: {
    displayShowMessageOnModal (messageId) {
      this.$store.dispatch('getMessage', messageId)
      this.$store.dispatch('setAsReadMessage', messageId)
      this.displayShowMessageModal = true
    }
  }
}
</script>

<style lang="scss">
  .unread-message:hover {
    color: #D20F6E;
    background-color: #f4f4f4 !important;
  }

  .message {
    cursor: pointer;
    font-weight: bold;
  }

  .documents-table {
    background-color: transparent !important;
  }

  .read-message {
    .year {
      background-color: #b4b4b4;
    }

    .date-square {
      background-color: #b4b4b4;
    }

    color: #b4b4b4;
  }

  .read-message:hover {
    color: #707070;
    background-color: #f4f4f4 !important;
  }

  .date-square {
    color: white !important;
    height: 48px;
    width: 48px;
    background-color: #D20F6E;
    border-radius: 10px !important;
    text-align: center;
    font-weight: bold;

    span {
      margin-top: 12px;
      margin-left: 12px;
      font-size: 12px;
    }
  }
</style>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="1200px">
      <v-card>
        <v-card-title>
          <div class="container text-right">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="close()"
                >
                  <v-icon class="close-icon">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
          <h2 class="dont-break message-title">{{ message.attributes.title }}</h2>
        </v-card-title>
        <v-card-text class="dont-break">
          {{ message.attributes.message }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col sm="6" class="text-center">
          </v-col>
          <v-col sm="6" class="text-right grey-link">
            <a
              @click="close()"
            > {{ $t('close') }}
            </a>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'showMessageModal',
  data () {
    return {
      dialog: true
    }
  },
  computed: {
    ...mapGetters(['message'])
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
